import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { isEmpty, isPresent, teamsEnv, userFullName } from "core/utils";
import { verifyAuthToken } from "core/channel/action";

import { allAssignments } from "modules/assignments/action";
import { fetchGraphData } from "modules/graph/action";
import * as assignmentSelector from "modules/assignments/selectors";
import AssignmentsSkeletalLoader from "modules/assignments/skeletalLoaders/AssignmentsSkeletalLoader";
import AssignmentError from "modules/assignments/AssignmentError";
import AssignmentTemplates from "modules/assignmentTemplates/AssignmentTemplates";

import Header from "./Header/Header";
import Home from "./Home";
import FreeTrialAlert from "core/components/FreeTrialAlert";
import {BackButton, PermissionAlert} from "core/components";
import StudentsList from "modules/studentsList/StudentsList";
import { attachmentFilestack } from "./attachments/actions";
import * as attachmentsSelector from "./attachments/selectors";
import ChannelOnboarding from "./channelOnboarding/channelOnboarding";
import ChannelOnboardingModal from "./channelOnboarding/channelOnboardingModal";
import { useSchoolConfig } from "../core/hooks";
import NurtureResources from "./nurtureResources/NurtureResources";
import DynamicAlerts from "./dynamicAlerts/DynamicAlerts";
import { useIntercom } from "react-use-intercom";
import Dashboard from "./dashboard/Dashboard";
import metricService from "../lib/metricService";

export const TABS = {
  dashboard: 'dashboard',
  assignments: 'assignments',
  assignments_templates: 'assignments_templates',
  manage_students: 'manage_students',
  classroom_settings: 'classroom_settings',
  help_resources: 'help_resources'
};

const Tab = () => {
  const [pulse, setPulse] = useState(0);
  const [destinationUrl, setDestinationUrl] = useState('');

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { channelOnboarding } = useSchoolConfig();

  const loading = useSelector(assignmentSelector.loading);
  const assignmentsError = useSelector(assignmentSelector.error);
  const storeAssignments = useSelector(assignmentSelector.getAssignments);
  const [searchParams] = useSearchParams();
  const navigateTo = useNavigate();
  const { boot } = useIntercom();

  const { tenantId, channelId } = teamsEnv;

  if (tenantId && channelId) {
    sessionStorage.setItem('tenantId', tenantId);
    sessionStorage.setItem('channelId', channelId);
  }

  const sessionTenantId = sessionStorage.getItem('tenantId');
  const sessionChannelId = sessionStorage.getItem('channelId');
  const sessionToken = localStorage.getItem('token');

  const [assignments, setAssignments] = useState([]);
  const [user, setUser] = useState({});
  const [activeTab, setActiveTab] = useState('assignments');
  const [error, setError] = useState(false);
  const [verifyAuthLoading, setVerifyAuthLoading] = useState(false);

  const filestackCredentials = useSelector(attachmentsSelector.filestackCredentials);

  const params = {
    tenantId: sessionTenantId,
    channelId: sessionChannelId,
  };

  const tabFromUrl = searchParams.get('activeTab');

  const authed = isPresent(user);
  const role = user?.role;

  const getAllAssignments = async () => {
    try {
      await dispatch(allAssignments(params));
      setError(null)
    } catch (e) {
      setError(e.message)
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const fetchAnalytics = async () => {
    try {
      await dispatch(fetchGraphData());
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const updateIntercomUser = user => {
    if (user.role === 'student') {
      return
    }

    boot({
      name: userFullName(user),
      avatar: { type: 'avatar', imageUrl: user?.avatar_url },
      customAttributes: user,
      userId: user?.id,
      email: user?.email,
      companies: [
        {
          companyId: user?.school,
          name: user?.school
        }
      ],
    });
  };

  const setMixpanelUser = (user) => {
    const userId = user.id
    const properties = {
      name: userFullName(user),
      ...user
    }

    metricService.setUser({userId, properties});
  }

  const handleTabChanged = (newTab) => {
    if ((tabFromUrl === TABS.classroom_settings && !channelOnboarding.completed)) {
      // Prevent navigating away from the classroom settings if the channel onboarding
      // has not been completed
      setPulse(pulse + 1);
      setDestinationUrl(`/tab?activeTab=${newTab}`)
    } else {
      navigateTo(`?activeTab=${newTab}`);
    }
  }

  const verifyAuth = (authToken, additionalParams = {}) => {
    setVerifyAuthLoading(true);
    dispatch(verifyAuthToken(authToken, { ...params, ...additionalParams }))
      .then(res => {
        setUser(res);
        setError(null)
        updateIntercomUser(res);
        setMixpanelUser(res);
      })
      .catch((error) => setError(error.message))
      .finally(() => setVerifyAuthLoading(false));
  };

  useEffect(
    () => {
      if (isPresent(sessionToken)) {
        verifyAuth(sessionToken);
      }
    }, []
  );

  useEffect(() => {
    if (Object.keys(TABS).includes(tabFromUrl)) {
      setActiveTab(tabFromUrl);
    }
  }, [tabFromUrl]);

  useEffect(
    () => {
      if (activeTab === TABS.assignments) {
        if (isEmpty(filestackCredentials)) dispatch(attachmentFilestack())
        getAllAssignments();
        fetchAnalytics();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]
  );

  useEffect(() => {
    if (isPresent(storeAssignments)) setAssignments(storeAssignments);
  }, [storeAssignments])

  return (
    <>
      {
        authed &&
          <>
            {/*<DynamicAlerts />*/}
            {
              [TABS.classroom_settings, TABS.help_resources].includes(activeTab) ?
                <BackButton underlyingMethod={() => handleTabChanged(TABS.assignments)} /> :
                <Header name={user?.first_name} role={role} activeTab={activeTab} onTabChanged={handleTabChanged} />
            }
          </>
      }
      {
        loading || verifyAuthLoading ?
          <AssignmentsSkeletalLoader /> :
          (assignmentsError || error) ?
            <AssignmentError errorMessage={error || assignmentsError} /> :
            (
              <div className="container">
                {
                  activeTab === TABS.dashboard &&
                  <Dashboard />
                }
                {
                  activeTab === TABS.assignments &&
                  <>
                    <PermissionAlert />
                    {
                      role === "teacher" &&
                        <>
                          <FreeTrialAlert />
                          <ChannelOnboardingModal assignmentsCount={assignments?.length || 0} />
                        </>
                    }

                    {/*{*/}
                    {/*  role === "teacher" &&*/}
                    {/*    <NewAssignmentButton />*/}
                    {/*}*/}

                    {authed &&
                      <Home authed={authed} assignments={assignments} role={role} />
                    }
                  </>
                }
                {
                  activeTab === TABS.assignments_templates &&
                  <AssignmentTemplates />
                }
                {
                  activeTab === TABS.manage_students &&
                  <StudentsList />
                }

                {
                  activeTab === TABS.classroom_settings &&
                  <ChannelOnboarding pulse={pulse} destinationUrl={destinationUrl} />
                }

                {
                  activeTab === TABS.help_resources &&
                  <NurtureResources />
                }
              </div>
            )
      }
    </>
  );
};

export default Tab;
