import React from "react";
import { Label } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";

const CustomDateInput = React.forwardRef(({ label, value, defaultValue, className, inputRef, ...otherProps }, ref) => (
  <>
    <Label>
      {label}
      {otherProps.required && (
        <span className="required">*</span>
      )}
    </Label>
    <input
      type="date"
      className={`${className} form-control ${otherProps.error ? 'error' : ''}`}
      defaultValue={defaultValue}
      ref={inputRef}
      {...otherProps} />
  </>
));

const DateInput = ({ label, className, ...otherProps }) => (
  <Flatpickr
    onChange={otherProps?.onChange}
    value={otherProps?.value}
    options={{
      enableTime: true,
      enableSeconds: true,
      defaultHour: 23,
      defaultMinute: 59,
      minTime: '00:00',
      maxTime: '23:59',
      minDate: (new Date()).setDate(new Date().getDate() + 1), // tomorrow
      time_24hr: true,
      allowInput: true,
      altInput: true,
      altFormat: "F j, Y H:i",
      ...otherProps
    }}
    render={({ defaultValue, value, ...props }, ref) => {
      return (<CustomDateInput value={value} label={label} className={className} defaultValue={value} inputRef={ref} {...props} />
    )}}
  />
);

export default DateInput;
